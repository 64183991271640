import React, { useState } from "react";
import { debounceFunc } from '../../utilities/utilities';
import { IconButton, Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

type ItemConfProps = {
    icon: any,
    buttonOnConfirm: any,
    questionLabel?: string,
    yesLabel?: string,
    noLabel?:string,
    disabled?: boolean
}

const IconConfItem: React.FC<ItemConfProps> = ({icon, buttonOnConfirm, questionLabel, yesLabel, noLabel, disabled}) => {
    const {t} = useTranslation()

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return(
        <>
            <IconButton
                disabled={disabled ? disabled : undefined} 
                onClick={handleOpen}
            >
                {icon}
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {questionLabel ?? t("sureQuestion")}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={debounceFunc(buttonOnConfirm)}>
                        {yesLabel ?? t("yes")}
                    </Button>
                    <Button onClick={debounceFunc(handleClose)} autoFocus>
                        {noLabel ?? t("no")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default IconConfItem