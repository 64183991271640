import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TitleWithBack from "../../custom/TitleWithBack";
import { Installation } from "../../../api/requests/installationsService";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import { primaryType } from "../../../costants/costants";
import { CalibrationType, actuator, plant, pump } from "./costant";
import FormSelect from "../../custom/select/FormSelect";
import { getAllDevices } from "../../../api/services/deviceService";
import { getJob, setJob } from "../../../api/services/installationsService";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

type CalibrationProps = {
  installation_id: string;
};

const Calibration: React.FC<CalibrationProps> = ({ installation_id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        const tmp = res.installations.filter(
          (i: any) => i.id === installation_id
        );
        if (tmp && tmp.length > 0) {
          setSelectedInstallation(tmp[0]);
          getJob(installation_id, "set_params").then((res) => {
            console.log("RES", res);
          });
        }
      }
    });
  }, []);

  const [calibrationsConfiguration, setCalibrationsConfiguration] = useState<
    any[]
  >([]);
  useEffect(() => {
    if (selectedInstallation) {
      if (selectedInstallation.model === "actuator") {
        setCalibrationsConfiguration(actuator(t));
      }
      if (selectedInstallation.model === "plant") {
        setCalibrationsConfiguration(plant(t));
      }
      if (selectedInstallation.model === "pump") {
        setCalibrationsConfiguration(pump(t));
      }
    }
  }, [selectedInstallation]);

  const handleSubmit = () => {
    let tmpRes: any = {};
    console.log("CALIBRATION", calibrationsConfiguration);
    calibrationsConfiguration.forEach((a: CalibrationType) => {
      let tmp = {};
      a.field.forEach((f: any) => {
        if (f.type === "switch") {
          tmp = { ...tmp, enabled: f.value };
        } else {
          if (f.name.endsWith("min")) {
            tmp = { ...tmp, min: f.value };
          }
          if (f.name.endsWith("max")) {
            tmp = { ...tmp, max: f.value };
          }
          if (f.name.endsWith("off")) {
            tmp = { ...tmp, offset: f.value };
          }
        }
      });
      tmpRes = { ...tmpRes, [a.field[0].name]: tmp };
    });
    console.log("TMPRES", tmpRes);
    if (selectedInstallation && selectedInstallation.model === "plant") {
      const body = {
        sm3: [
          {
            t: Number(tmpRes.tsoil1.offset),
            h: Number(tmpRes.hsoil1.offset),
            ec: Number(tmpRes.ecsoil1.offset),
          }, //offset valori pianta 1
          {
            t: Number(tmpRes.tsoil2.offset),
            h: Number(tmpRes.hsoil2.offset),
            ec: Number(tmpRes.ecsoil2.offset),
          }, //offset valori pianta 2
          {
            t: Number(tmpRes.tsoil3.offset),
            h: Number(tmpRes.hsoil3.offset),
            ec: Number(tmpRes.ecsoil3.offset),
          }, //offset valori pianta 3
          {
            t: Number(tmpRes.tsoil4.offset),
            h: Number(tmpRes.hsoil4.offset),
            ec: Number(tmpRes.ecsoil4.offset),
          }, //offset valori pianta 4
        ],
        bat: Number(tmpRes.batt.offset), //offset tensione batteria
        sol: Number(tmpRes.solar.offset), //offset tensione pannello solare
        ev_in: tmpRes.ev.enabled ? 1 : 0, //segnale elettrovalvola
        bme: {
          t: Number(tmpRes.tint.offset),
          h: Number(tmpRes.hint.offset),
          p: Number(tmpRes.patm.offset),
        }, // offset temperatura umidità pressione
      };
      setJob(installation_id, selectedInstallation?.model || "", {
        calibrations: body,
      }).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("calibrationSendSuccess"), {
            variant: "success",
          });
          history.goBack();
        } else {
          enqueueSnackbar(t("calibrationSendError"), { variant: "error" });
        }
      });
    }
    if (selectedInstallation && selectedInstallation.model === "pump") {
      const body = {
        w_dir: {
          min: Number(tmpRes.wdir.min),
          max: Number(tmpRes.wdir.max),
          off: Number(tmpRes.wdir.offset),
        },
        w_spd: {
          min: Number(tmpRes.wvel.min),
          max: Number(tmpRes.wvel.max),
          off: Number(tmpRes.wvel.offset),
        },
        p_pre: {
          min: Number(tmpRes.ppref.min),
          max: Number(tmpRes.ppref.max),
          off: Number(tmpRes.ppref.offset),
        },
        p_pos: {
          min: Number(tmpRes.ppostf.min),
          max: Number(tmpRes.ppostf.max),
          off: Number(tmpRes.ppostf.offset),
        },
        fert: {
          min: Number(tmpRes.flvl.min),
          max: Number(tmpRes.flvl.max),
          off: Number(tmpRes.flvl.offset),
        },
        t_ext: Number(tmpRes.text.offset),
        curr: Number(tmpRes.coil.offset),
        bme: {
          t: Number(tmpRes.tint.offset),
          h: Number(tmpRes.hint.offset),
          p: Number(tmpRes.patm.offset),
        },
        m_pub: Number(tmpRes?.mpub?.offset || 0),
      };
      setJob(installation_id, selectedInstallation?.model || "", {
        calibrations: body,
      }).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("calibrationSendSuccess"), {
            variant: "success",
          });
          history.goBack();
        } else {
          enqueueSnackbar(t("calibrationSendError"), { variant: "error" });
        }
      });
    }
  };

  return (
    <>
      <TitleWithBack title={t("calibration")} />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                {calibrationsConfiguration.map((a: CalibrationType) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      xs={12}
                      justifyContent="space-between"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">{a.label}</Typography>
                      </Grid>
                      {a.field.map((f: any) => {
                        if (f.type === "switch") {
                          return (
                            <Grid item container xs={2} alignItems="center">
                              <Grid item>
                                <Switch
                                  defaultChecked={f.value}
                                  onChange={(e) => (f.value = e.target.checked)}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="body1">
                                  {t("enabled")}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }
                        if (f.type === "number") {
                          return (
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                fullWidth
                                type="number"
                                label={f.label}
                                variant="outlined"
                                defaultValue={f.value}
                                onChange={(e) => (f.value = e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {f.endAdornment ? f.endAdornment : ""}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          );
                        }
                        if (f.type === "select") {
                          return (
                            <Grid item xs={6}>
                              <FormSelect
                                placeholder={f.label}
                                keyValue={f.name}
                                name={f.name}
                                onChange={(e: any) =>
                                  (f.value = e.target.value)
                                }
                                options={f.options ? f.options : []}
                              />
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  );
                })}
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() => {
                        console.log("reset");
                      }}
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Calibration;
