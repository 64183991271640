export type CalibrationType = {
  label: string;
  field: {
    label: string;
    name: string;
    type: string;
    value?: string | number | boolean;
    options?: { label: string; value: string }[];
    endAdornment?: string;
  }[];
};

export const actuator = (t: any) => [
  {
    label: "Temperatura interna",
    field: [
      {
        label: t("status"),
        name: "tint",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tintoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità interna",
    field: [
      {
        label: t("status"),
        name: "hint",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hintoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Pressione atmosferica",
    field: [
      {
        label: t("status"),
        name: "patm",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "patmoff",
        type: "number",
        value: "",
      },
    ],
  },
];

export const plant = (t: any) => [
  {
    label: "Tensione di batteria",
    field: [
      {
        label: t("status"),
        name: "batt",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "battoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Tensione pannello solare",
    field: [
      {
        label: t("status"),
        name: "solar",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "solaroff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Stato elettro-valvola",
    field: [
      {
        label: t("status"),
        name: "ev",
        type: "switch",
        value: false,
      },
    ],
  },
  {
    label: "Temperatura vaso 1",
    field: [
      {
        label: t("status"),
        name: "tsoil1",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tsoil1off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità vaso 1",
    field: [
      {
        label: t("status"),
        name: "hsoil1",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hsoil1off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Conducibilità elettrica vaso 1",
    field: [
      {
        label: t("status"),
        name: "ecsoil1",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "ecsoil1off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Temperatura vaso 2",
    field: [
      {
        label: t("status"),
        name: "tsoil2",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tsoil2off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità vaso 2",
    field: [
      {
        label: t("status"),
        name: "hsoil2",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hsoil2off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Conducibilità elettrica vaso 2",
    field: [
      {
        label: t("status"),
        name: "ecsoil2",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "ecsoil2off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Temperatura vaso 3",
    field: [
      {
        label: t("status"),
        name: "tsoil3",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tsoil3off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità vaso 3",
    field: [
      {
        label: t("status"),
        name: "hsoil3",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hsoil3off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Conducibilità elettrica vaso 3",
    field: [
      {
        label: t("status"),
        name: "ecsoil3",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "ecsoil3off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Temperatura vaso 4",
    field: [
      {
        label: t("status"),
        name: "tsoil4",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tsoil4off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità vaso 4",
    field: [
      {
        label: t("status"),
        name: "hsoil4",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hsoil4off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Conducibilità elettrica vaso 4",
    field: [
      {
        label: t("status"),
        name: "ecsoil4",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "ecsoil4off",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Temperatura interna",
    field: [
      {
        label: t("status"),
        name: "tint",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tintoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità interna",
    field: [
      {
        label: t("status"),
        name: "hint",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hintoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Pressione atmosferica",
    field: [
      {
        label: t("status"),
        name: "patm",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "patmoff",
        type: "number",
        value: "",
      },
    ],
  },
];

export const pump = (t: any) => [
  {
    label: "Canale 4-20mA - velocità del vento",
    field: [
      {
        label: t("status"),
        name: "wvel",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "wveloff",
        type: "number",
        value: "",
      },
      {
        label: t("minValue"),
        name: "wvelmin",
        type: "number",
        value: "",
      },
      {
        label: t("maxValue"),
        name: "wvelmax",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Canale 4-20mA - direzione del vento",
    field: [
      {
        label: t("status"),
        name: "wdir",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "wdiroff",
        type: "number",
        value: "",
      },
      {
        label: t("minValue"),
        name: "wdirmin",
        type: "number",
        value: "",
      },
      {
        label: t("maxValue"),
        name: "wdirmax",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Canale 4-20mA - pressione pre filtro",
    field: [
      {
        label: t("status"),
        name: "ppref",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "pprefoff",
        type: "number",
        value: "",
      },
      {
        label: t("minValue"),
        name: "pprefmin",
        type: "number",
        value: "",
      },
      {
        label: t("maxValue"),
        name: "pprefmax",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Canale 4-20mA - pressione post filtro",
    field: [
      {
        label: t("status"),
        name: "ppostf",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "ppostfoff",
        type: "number",
        value: "",
      },
      {
        label: t("minValue"),
        name: "ppostfmin",
        type: "number",
        value: "",
      },
      {
        label: t("maxValue"),
        name: "ppostfmax",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Canale 4-20mA - livello fertilizzante",
    field: [
      {
        label: t("status"),
        name: "flvl",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "flvloff",
        type: "number",
        value: "",
      },
      {
        label: t("minValue"),
        name: "flvlmin",
        type: "number",
        value: "",
      },
      {
        label: t("maxValue"),
        name: "flvlmax",
        type: "number",
        value: "",
      },
    ],
  },
  /*
  {
    label: "Consumo di acqua",
    field: [
      {
        label: t("status"),
        name: "wmeter",
        type: "switch",
        value: false,
      },
    ],
  },
  */
  {
    label: "Canale NTC - temperatura esterna",
    field: [
      {
        label: t("status"),
        name: "text",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "textoff",
        type: "number",
        value: "",
      },
    ],
  },
  /*
  {
    label: "Canale di corrente - consumo di potenza sala pompe",
    field: [
      {
        label: t("status"),
        name: "curr",
        type: "switch",
        value: false,
      },
    ],
  },
  */
  {
    label: "Numero di avvolgimenti",
    field: [
      {
        label: t("status"),
        name: "coil",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "coiloff",
        type: "number",
        value: "",
      },
      /*
      {
        label: t("minValue"),
        name: "coilmin",
        type: "number",
        value: "",
      },
      {
        label: t("maxValue"),
        name: "coilmax",
        type: "number",
        value: "",
      },
      */
    ],
  },
  {
    label: "Temperatura interna",
    field: [
      {
        label: t("status"),
        name: "tint",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "tintoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Umidità interna",
    field: [
      {
        label: t("status"),
        name: "hint",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "hintoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Pressione atmosferica",
    field: [
      {
        label: t("status"),
        name: "patm",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "patmoff",
        type: "number",
        value: "",
      },
    ],
  },
  {
    label: "Consumo acqua",
    field: [
      {
        label: t("status"),
        name: "mpub",
        type: "switch",
        value: false,
      },
      {
        label: t("offset"),
        name: "mpuboff",
        type: "number",
        value: "",
      },
    ],
  },
];
